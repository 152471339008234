<!--<app-carousel  [customCss]="'b-0 p-0'" [id]="'carouselCP'" [mode]="'slider'" [type]="'CAPACITACIONES_PRINCIPAL'" [quantity]="1"></app-carousel>-->
<app-carousel  [customCss]="'b-0 p-0'" [id]="'carouselPP'" [mode]="'slider'" [type]="'PRODUCTOS_PRINCIPAL'" [quantity]="1"></app-carousel>


<div class="header-filter">
  <app-course-filter
    (searchFilter)="filter($event)"
    (deleteChip)="deleteSelected($event)"
    [avales]="avales"
    [specialty]="specialty"
    [chips]="chips"
    [modalidades]="modalidades"
    [vendedores]="vendedores"
    #chipsUpdate>
  </app-course-filter>
  <app-filter-google-maps
    class="filter-google"
    *ngIf="showComponent"
    (searchFilter)="filterGoogleMaps()"
    [eventEnabled]=true
    [inputLAvel]=dir
    [placeholder]="placeholderText"
    [courseForm]=courseForm
  ></app-filter-google-maps>
  <div class="col-lg-3 pad">
    <select class="form-control" (change)="onChange($event.target)">
      <option [value]="''" selected>Seleccione una distancia</option>
      <option [value]="'100'">Hasta 100 km</option>
      <option [value]="'250'">Hasta 250 km</option>
      <option [value]="'500'">Hasta 500 km</option>
      <option [value]="'750'">Hasta 750 km</option>
      <option [value]="''">Mas de 750 km</option>
    </select>

  </div>
</div>



<div class="row ">
  <div class="col-2 d-none d-md-block">
    <div class="card ">
      <div *ngIf="chips && chips.length">
        <div *ngFor="let chip of chips let i=index;"  class="mr-b" title="{{chip.nombre}}">
          <h6 *ngIf="chip.keyType === 'avales'">{{chip.tipo}} seleccionada</h6>
          <h6 *ngIf="chip.keyType === 'modalidad'">{{chip.tipo}} seleccionada</h6>
          <h6 *ngIf="chip.keyType === 'vendedor'">{{chip.tipo}}</h6>
          <h6 *ngIf="chip.keyType === 'especialidad'">{{chip.tipo}} seleccionados</h6>
          <div class="chip-filter" *ngIf="chip.keyType !== 'texto' && chip.keyType !== 'distancia'">
            <span class="name">{{chip.nombre}}</span>
            <span class="closebtn"  (click)="deleteSelected(chip)" onclick="this.parentElement.style.display='none'">×</span>
          </div>
        </div>
      </div>
      <div class="card-body">
        <!-- Especialidades -->
        <app-sidebar-collapse-filter
          *ngIf="specialty?.length"
          name="Especialidades"
          [items]="specialty"
          (onSelect)="selected('especialidad', $event)">
        </app-sidebar-collapse-filter>

        <!-- Modalidades -->
        <app-sidebar-collapse-filter
          *ngIf="modalidades?.length"
          name="Modalidades"
          [items]="modalidades"
          (onSelect)="selected('modalidad', $event)">
        </app-sidebar-collapse-filter>

        <!-- Avales -->
        <app-sidebar-collapse-filter
          *ngIf="avales?.length"
          name="Aval"
          [items]="avales"
          (onSelect)="selected('aval', $event)">
        </app-sidebar-collapse-filter>

        <!-- Vendedores -->
        <app-sidebar-collapse-filter
          *ngIf="vendedores?.length"
          name="Vendedores"
          [items]="vendedores"
          value="razonSocial"
          (onSelect)="selected('vendedor', $event)">
        </app-sidebar-collapse-filter>

      </div>
    </div>
  </div>
  <div class="col-10">

    <div class="row product-grid" *ngIf="cursos && cursos.length">
      <div class="col col-12 col-md-6 col-lg-4 col-xxl-3" *ngFor="let curso of cursos">
        <app-product-card
          [slug]="curso.slug"
          [courses] = true
          [urlImg]="curso.imagenes && curso.imagenes.length ? curso.imagenes[0].realpath : curso.realpath "
          [title]="curso.nombre"
          [puntuacion] = "curso.puntuacion"
          [id]="curso.id"
          [precio]="curso.precio"
          [preciodescuento]="curso.preciodescuento"
          (click)="goToCourseDetail(curso.slug ? curso.slug : curso.id)"
          [cursoPadre]="curso.cursoPadre?.nombre"
          [vendedores]="curso.vendedor"
          [fechaInicio]="curso.inicio"
        ></app-product-card>
        <!--        -->
      </div><!--end row-->

    </div>
    <div class="ta-c" *ngIf="!cursos || !cursos.length">
      <p *ngIf="!chips && !chips.length">{{noExistenCursos}}</p>
      <p *ngIf="chips && chips.length">{{showResult ? noHayResultadosFiltro : buscandoResultadosFiltro}}</p>
    </div>
    <app-carousel  [customCss]="'b-0 p-0'" [id]="'carouselCR'" [mode]="'slider'" [type]="'CAPACITACIONES_RESULTADOS'" [quantity]="1"></app-carousel>

  </div>
</div>
