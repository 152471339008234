<div class="card">
  <app-header [title]="'Curso'"
              [description] = "'Recordá que el administrador debe aprobar el curso para su visualización en Capacitación: Cursos'"
              [showButtonBack] = true
              (backPressed)="navigateBack()"></app-header>
  <div class="card-body px-4" *ngIf="courseForm">
    <div class="form-body mt-4">
      <form  [formGroup]="courseForm" >
        <div class="row" style="justify-content: center;">

          <div class="col-lg-12 p-1">
            <div class="border border-3 p-4 rounded">
              <div class="row">
                <div class="col-lg-4">
                  <label for="inputCourseTitle" class="form-label">Nombre</label>
                  <input type="text" class="form-control" id="inputCourseTitle" formControlName="nombre"
                         placeholder="Curso" [class.is-invalid]="nombreNoValido()">
                  <div *ngIf="nombreNoValido()" class="invalid-feedback">Ingrese nombre</div>
                </div>
                <div class="col-lg-3">
                  <label for="inputCourseTitle" class="form-label">¿Activo?</label>
                  <select class="mb-3 form-control" formControlName="activo">
                    <option [value]="true">Activo</option>
                    <option [value]="false">Inactivo</option>
                  </select>
                </div>

                <div class="col-lg-3">
                  <label for="inputCourseTitle" class="form-label">¿Aprobado?</label>
                  <select class="mb-3 form-control" formControlName="aprobado" disabled>
                    <option [value]="true">Activo</option>
                    <option [value]="false">Inactivo</option>
                  </select>
                </div>
                <div class="col-lg-2" style="margin-top: 30px;" *ngIf="!!id">
                  <button class="btn btn-outline-primary" (click)="modalDuplicar(modalConfirmar)">
                    Duplicar curso
                  </button>
                </div>

                </div>

              <div class="mb-3">
                <label class="form-label">Descripción</label>
                <app-rich-text [formGroup]="courseForm" [placeholder]="'Descripción'" name="descripcion"></app-rich-text>
                <!-- <angular-editor formControlName="descripcion" [config]="editorConfig" ></angular-editor> -->
                <div *ngIf="descripcionNoValida()" class="invalid-feedback">Ingrese descripción</div>
              </div>

              <div class="row">
                <div class="col-lg-3">
                  <div class="mb-3">
                    <label class="form-label">Inicio</label>

                    <input class="form-control" placeholder="dd-mm-yyyy" [value]="courseForm.controls.inicio.value"
                           (focus)="d.open()" (dateSelect)="d.close();"
                           id="courseStart" formControlName="inicio" [class.is-invalid]="fechaNoValida()"
                           ngbDatepicker #d="ngbDatepicker">
                    <div *ngIf="fechaNoValida()" class="invalid-feedback">Ingrese fecha</div>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="mb-3">
                    <div class="col-auto">
                      <label for="inputFromDate" class="form-label" >Hora de inicio</label>
                      <input type="time" class="form-control" id="inputFromDate" formControlName="horaInicio">
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="mb-3">
                    <label for="institution" class="form-label">Institución</label>
                    <input type="text" class="form-control" id="institution" placeholder="Institución"
                           formControlName="institucion" [class.is-invalid]="institucionNoValida()"
                           list="institucion"/>
                    <datalist id="institucion">
                      <option [value]="currentUser.razonSocial">
                    </datalist>
                    <div *ngIf="institucionNoValida()" class="invalid-feedback">Ingrese la institución</div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class=" mt-lg-0 col-lg-12 p-1">
            <div class="border border-3 p-4 rounded">
              <div class="row g-3">
                <div class="mb-3 col-lg-3">
                  <label for="dictator" class="form-label">Dictantes</label>
                  <input type="text" class="form-control" id="dictator" formControlName="dictantes"
                         placeholder="Dictantes" [class.is-invalid]="dictantesNoValido()">
                  <div *ngIf="dictantesNoValido()" class="invalid-feedback">Dictantes no válido</div>

                </div>

                <div class="mb-3 col-lg-4">
                  <label for="dictator" class="form-label">Especialidad</label>
                  <ng-multiselect-dropdown
                    [placeholder]="'Seleccione la especialidad'"
                    formControlName="especialidad"
                    [settings]="dropdownSettings"
                    [data]="specialties">
                  </ng-multiselect-dropdown>
                  <div *ngIf="especialidadesNoValida()" class="invalid-feedback">Seleccione una especialidad
                  </div>
                </div>

                <div class="mb-3 col-lg-5">
                  <label class="form-label">Aval</label>
                  <ng-multiselect-dropdown
                    [placeholder]="'Seleccione Aval'"
                    formControlName="aval"
                    [settings]="dropdownSettingsAval"
                    [data]="avales">
                  </ng-multiselect-dropdown>

                  <div *ngIf="avalesNoValida()" class="invalid-feedback">Aval no válida</div>
                </div>

                <div class="col-lg-2">
                  <label for="inputPrice" class="form-label">Precio más bajo</label>
                  <input type="number" step="1" class="form-control" id="inputPrice" placeholder="$000" min="0"
                         formControlName="precio">
                  <div *ngIf="precioNoValido()" class="invalid-feedback">Ingrese precio</div>
                </div>

                <div class="col-12">
                  <label for="inputPrice" class="form-label">Palabras Claves</label>
                  <input class="form-control" placeholder="Palabras Claves" formControlName="claves">
                </div>

                <div class="mb-3 col-lg-2">
                  <label for="workload" class="form-label">Carga horaria</label>
                  <input type="text" class="form-control" id="workload" placeholder="Carga horaria"
                         formControlName="cargaHoraria" [class.is-invalid]="cargaHorariaNoValida()">
                  <div *ngIf="cargaHorariaNoValida()" class="invalid-feedback">Ingrese carga horaria</div>
                </div>

                <div class="mb-3 col-lg-4">
                  <label class="form-label">Modalidad</label>
                  <ng-multiselect-dropdown
                    [placeholder]="'Seleccione Modalidad'"
                    formControlName="modalidad"
                    [settings]="dropdownSettingsAval"
                    (onSelect)="onItemSelect($event)"
                    [data]="modalidades">
                  </ng-multiselect-dropdown>

                  <div *ngIf="modalidadesNoValida()" class="invalid-feedback">Modalidad no válida</div>
                </div>
                <div class="mb-3 col-lg-4 button-add">
<!--                  [disabled]="courseForm.invalid"-->
                  <button type="submit" class="btn btn-primary px-5 " *ngIf="!addCursoIntracongresos && showButtonCourses"  [disabled]="courseForm.invalid" (click)="addSubcourse()">
                    Guardar y Agregar Cursos
                  </button>
                </div>
              </div>

              <div class="mt-3 mb-3 col-md-12">
                <label  class="form-label">Detalle todos los precios según INSTITUCIÓN, forma de pagos, etc. </label>
                <app-rich-text
                  [placeholder]="'Detalle todos los precios según INSTITUCIÓN, forma de pagos, etc.'"
                  [formGroup]="courseForm" name="precioComentario"></app-rich-text>
              </div>

              <div class=" col-lg-6">
                <label for="dictator" class="form-label">Productos relacionados <small>Máximo 5 productos</small></label>
                <ng-multiselect-dropdown
                  [placeholder]="'Seleccione productos relacionados'"
                  formControlName="relatedProducts"
                  [settings]="dropdownSettingsProduct"

                  [data]="products"
                  [(ngModel)]="selectedProduct"
                  >
                </ng-multiselect-dropdown>
              </div>
              <div class="col-md-6 mt-3">
                <div class="form-check">
                  <input class="form-check-input"
                         name="physicalAddress"
                         formControlName="physicalAddress"
                         (change)="changeAddress()"
                         type="checkbox">
                  <label class="form-check-label">¿Es un capacitación presencial?</label>

                </div>
              </div>
              <div class="mt-3">

                <app-filter-google-maps
                  *ngIf="showComponent"
                  [inputLAvel] = dir
                  [title] = "physicalAddress"
                  [placeholder] = "placeholderText"
                  [showMap] = true
                  [courseForm] = courseForm
                  [helper] = true
                ></app-filter-google-maps>

              </div>

              ”


              <div class="mt-5 mb-3 col-md-12">
                <label  class="form-label">¿Quieres compartir un video de YouTube que invite a los usuarios a registrarse en este curso? </label>
                <p class="m-0"><small>Para que el video quede visible en la web deberás cambiar en el link, haciendo doble click, la frase donde dice <b>watch?v=</b> por la palabra <b>embed/</b>.</small></p>

                <tag-input
                  [addOnBlur]="true"
                  [editable]="true"
                  secondaryPlaceholder="Ej: https://www.youtube.com/embed/kCW7u7AeVg4"
                  placeholder="Ej: https://www.youtube.com/embed/kCW7u7AeVg4"
                  [addOnPaste]="true"
                  [pasteSplitPattern]="','"
                  [theme]="'dark'"
                  [formControlName]="'videos'"
                  name="items"></tag-input>
              </div>

            </div>
          </div>
          <p class="mt-4 mb-1 pointer" style="text-align: right">
            <b class="text-primary" (click)="save(true)">Guardar y Ordenar Imagenes</b>
          </p>
          <div class="mt-4 mt-lg-0 border border-3 p-1 rounded">
            <div class="imagen-center">
              <div class="imageuploadify well">
                <div class="imageuploadify-overlay">
                  <i class="fa fa-picture-o"></i>x
                </div>
                <div class="imageuploadify-images-list text-center">
                  <br>
                  <div class="imageuploadify-container" style="margin-left: 24px;" *ngFor="let image of fileListEdit" >
                    <button type="button" class="btn btn-danger bx bx-x" (click)="remove(image)"></button>
                    <img *ngIf="image" [src]="domSanitizer.bypassSecurityTrustUrl(image.realpath)">
                  </div>
                  <div class="imageuploadify-container" style="margin-left: 24px;" *ngFor="let image of fileList" >
                    <button type="button" class="btn btn-danger bx bx-x" (click)="remove(image)"></button>
                    <img [src]="transform(url.createObjectURL(image))">
                  </div>
                </div>
              </div>
              <div class="mt-1">
                <p class="text-warning mb-1">Recuerde cargar imagenes de Formato cuadrado, sobre todo la imagen principal (primera imagen a cargar).
                  <br>SI LA CARGO EN FORMATO CUADRADO IGNORE ESTE MENSAJE</p>
              </div>
            </div>

            <div class="col-md-12 button-file mb-2">
              <input #file type="file" accept="image/*" formControlName="imagen"  multiple (change)="fileChange($event)">
              <a  #upload (click)="file.click()" class="btn btn-outline-primary radius-15">Cargar imagen</a>
            </div>
          </div>

        </div>
        <ng-container *ngIf="precurso && precurso.cursoIntracongresos?.length ">
          <div class="p-3">
            <h3>CURSOS INTRACONGRESOS</h3>
            <div class="section-container">
              <app-sub-course [edit]="true" [items]="precurso.cursoIntracongresos" [idCourse] = "id" ></app-sub-course>
            </div>
          </div>
        </ng-container>

        <div class="py-4">
          <ng-container *ngFor="let r of requireds">
            <p class="text-danger mb-0 px-4" *ngIf="!courseForm.get(r.key)?.value || (r.array && !courseForm.get(r.key)?.value?.length)">
              * Falta completar el campo <b [innerText]="(r.text ? r.text : r.key)|uppercase"></b>
            </p>
          </ng-container>
        </div>

        <div class="fixed-bottom floating-box">
          <button type="submit" class="btn btn-primary px-5" (click)="save()" [disabled]="courseForm.invalid">
            Guardar Curso
          </button>
        </div>


      </form>

      <!--end row-->
    </div>
  </div>
</div>


<ng-template #modalConfirmar let-modal>

  <div class="modal-content">
    <div class="modal-header ">
      <h5 class="modal-title">Duplicar Curso</h5>
    </div>
    <div class="modal-body">
      <p>Al confirmar realizarás una copia de este curso.</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="cancelarDuplicar()">Cancelar</button>
      <button type="button" class="btn btn-dark" (click)="duplicarCurso()">Confirmar </button>
    </div>
  </div>
  <!--  end mobile-->
</ng-template>
