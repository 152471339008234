<div class="card bg-dark mb-4 " *ngIf="!courses">
  <img src="{{urlImg}}" class="card-img-top" alt="..." (click)="isProduct?goToProductDetail():''">
  <div class="card-body">
    <div (click)="isProduct?goToProductDetail():''">
      <h6 class="card-title cursor-pointer" [title]="title">{{title}}</h6>
      <p class="cursor-pointer card-subT mb-1">{{subtitle}}</p>
      <p *ngIf="marca" class=" mb-1 cursor-pointer ">{{marca}}</p>

        <div class="precio">
          <p *ngIf="precioDesde">Precios desde ${{getPrecioDesde()}}</p>
        </div>
        <div class="vendedores gold-border" *ngIf="vendedores && vendedores.length">
          <ng-container *ngFor="let v of vendedores">
            <img *ngIf="!vendedorId || v.id == vendedorId"  [src]="v.imagen" [alt]="v.nombre">
          </ng-container>
        </div>


    </div>
    <div class="actions" *ngIf="action">
      <app-stars [value]="puntuacion"></app-stars>
    </div>
  </div>
  <div *ngIf="action" class="card-footer justific">
    <a class="btn">
      <i class='lni lni-heart-filled m-0 text-danger' title="Quitar de favorito." (click)="removeFavorite()"></i>
    </a>
    <a class="btn">
      <i class='lni lni-eye m-0' title="Ver detalle" (click)=" goToProductDetail()"></i>
    </a>
  </div>
  <div *ngIf="!action" class="card-footer justific">
    <app-stars [value]="puntuacion"></app-stars>

    <div style="display: inline-flex">

      <a  [ngbTooltip]="'Agregar al carrito'" >
        <img class="icon-img" src="../../assets/images/img_9.png" (click)="addCart(content2,'product/')" >
      </a>
      <app-favorite-product [isFavourite]="isFavorite" [idProduct]="id" [product]="product" *ngIf="isProduct" (removeProductFavorite)="refresh()"></app-favorite-product>
    </div>


  </div>
</div>

<div class="card  bg-dark mb-4 gold-border" *ngIf="courses">
  <img src="{{urlImg}}" class="card-img-top" [alt]="title">
  <div class="card-body">
    <div>
      <h6 class="card-title cursor-pointer" [title]="title">{{title}}</h6>
    </div>
    <p class="mb-1" *ngIf="fechaInicio">Fecha de Inicio: {{fechaInicio}}</p>
    <div class="precio">
      <p *ngIf="precio">Precios desde ${{precio}}</p>
    </div>
    <div class="vendedores d-flex vendedor-curso">
      <div class="text-center p-1 ">
      <img [src]="vendedores.imagen" [alt]="vendedores.razonSocial">
      </div>
      <div>
        <span class="text-dark" style="margin: 0px">{{vendedores.razonSocial}}</span>
        <app-stars [value]="5"></app-stars>
      </div>
    </div>

    <div class="precio" [title]="'Curso dentro de'+ cursoPadre ">
      <p *ngIf="cursoPadre" >Curso dentro de <b>{{cursoPadre}}</b></p>
    </div>
  </div>
  <div class="card-footer">
    <app-stars [value]="puntuacion"></app-stars>
  </div>

</div>
<ng-template #content2 let-modal>

  <div class="modal-content ">


    <div class="modal-body">
      <div class="row icon-alig">
        <img class="me-2" src="assets/images/icons/icon-check.svg"
             width="16" alt="check">
      </div>
      <p>EL PRODUCTO SE HA AGREGADO AL CARRITO DE COMPRA.</p>
      <div class="d-felx">
        <!--        <button type="button" class="btn btn-outline-primary">Seguir comprando</button>-->
        <button type="button" class="btn btn-outline-modal gold-border" (click)="closeModal()"><span>Seguir comprando</span></button>
        <button type="button" class="btn btn-outline-primary-modal gold-border mr-l-10" (click)="redirection('cart/')"><span>ir al Carrito</span></button>
      </div>
    </div>
  </div>
  <!--  end mobile-->
</ng-template>
<ng-template #content let-modal>

  <div class="modal-content">
    <div class="modal-header ">
      <h5 class="modal-title">producto existente </h5>
    </div>
    <div class="modal-body">
      <p>Este producto ya se encuentra en el carrito, desea modificarlo.</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light " (click)="closeModal()">No</button>
      <button type="button" class="btn btn-dark" (click)="prductEdit()">Si</button>
    </div>
  </div>
  <!--  end mobile-->
</ng-template>
