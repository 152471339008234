import { Component, Input, OnInit } from '@angular/core';
import { HttpErrorResponse } from "@angular/common/http";
import { ProductService } from "../../../services/product.service";
import { Router } from "@angular/router";
import { baseUrlImg } from "../../../../environments/environment";

@Component({
  selector: 'app-home-seller-card',
  templateUrl: './home-seller-card.component.html',
  styleUrls: ['./home-seller-card.component.scss']
})
export class HomeSellerCardComponent implements OnInit {
  vendedores:Array<any>;
  target: string;
  cols: string;
  colsBlock: string;
  @Input() mode: string;  // Block or Slider
  @Input() type: string;  // PRINCIPAL, EMPRESAS, CATEGORIAS
  @Input() quantity: number;  // Number of banners to show
  protected readonly baseUrlImg = baseUrlImg;
  constructor(private productService: ProductService,
              private router: Router) { }

  ngOnInit(): void {
    this.quantity =12;
    this.cols = `col col-${this.quantity * 2} col-md-${this.quantity} col-lg-${this.quantity}`;
    this.colsBlock = `col-md-${ 12 / this.quantity }`;
    this.getSeller();
  }

  getSeller(): void {
    this.productService.getSeller().subscribe((response: Array<any>) => {
      this.vendedores = response;
    }, (errorResponse: HttpErrorResponse) => {
      console.error(errorResponse);

    });
  }



  search(vendedor): void {
    this.router.navigate( [vendedor.buscador], { queryParams: { vendedor: vendedor.id, nombre: vendedor.razonSocial}});
  }
}
