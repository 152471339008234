import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent implements OnInit {
  @Input() title: string;
  @Input() position: string;
  @Input() textTransform: string;
  @Input() link: boolean;
  @Input() titles: Array<any>;
  constructor() { }

  ngOnInit(): void {

  }

}
