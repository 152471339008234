import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {Subscription} from 'rxjs';
import {Carousel, CarouselService} from './carousel.service';
import { EspecialidadService, Specialty } from '../../services/especialidad.service';
import { HttpErrorResponse } from '@angular/common/http';
import {baseUrlImg } from '../../../environments/environment';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit, OnDestroy {

  @Input() name = '';

  @Input() interval = 5000;
  @Input() lines;
  @Input() hover = false;
  @Input() showArrow = false;
  @Input() id: number; // ID del carousel
  @Input() mode: string;  // Block or Slider
  @Input() type: string;  // PRINCIPAL, EMPRESAS, CATEGORIAS
  @Input() quantity: number;  // Number of banners to show

  @Input() showDescription: boolean;
  @Input() customCss?: string;
  @Output() setImages: EventEmitter<any> = new EventEmitter();
  baseUrlImg: string;
  bannersService: Carousel[];
  banners: Carousel[];
  subscriptions: Subscription[];
  target: string;
  cols: string;
  colsBlock: string;
  // carrusel de specialty
  specialty: Array<Specialty>;

  colors = ['#3494da', '#d4a711' , '#9ca64f', '#CA5008', 'orange', 'blue', '#fefe72', '#ec7777', 'violet'];

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: any; }; }) {
    // this.innerWidth = event.target.innerWidth;
    if ( this.mode === 'slider' ||  this.mode === 'slider-prueba')  {
      this.banners = this.setSliderBanners(this.bannersService);
    }
  }

  constructor(private carouselService: CarouselService, private especialidadService: EspecialidadService) {
    this.mode = '';
    this.type = '';
    this.baseUrlImg = baseUrlImg;
    // this.baseUrl = 'http://localhost/odontologia';
    this.banners = [];

    this.subscriptions = [];
  }

  ngOnInit(): void {

    this.cols = `col col-${this.quantity * 2} col-md-${this.quantity} col-lg-${this.quantity}`;
    this.colsBlock = `col-md-${ 12 / this.quantity }`;
    this.target = `#${this.id}`;
    if (this.type) {
      // if(this.type === 'especialidades'){
      //   this.bannersService = [];
      //   this.especialidadService.getSpecialty().subscribe(
      //     (response: Array<Specialty>) => {
      //       if (response.length){
      //         this.specialty = response;
      //         response.map(( specialty: Specialty) => {
      //           const id = String(specialty.id);
      //           this.bannersService.push({
      //             id: id,
      //             titulo: specialty.nombre,
      //             imagen: specialty.imagen,
      //             visible: specialty.visible,
      //           });
      //         });
      //         this.banners = this.setSliderBanners(this.bannersService);
      //       }
      //     }, (error: HttpErrorResponse) => {
      //     }
      //   );
      // }else{
        this.subscriptions.push(
          this.carouselService.getBannersByType(this.type).subscribe((resp: Array<Carousel>) => {
            if (resp.length > 0) {
              this.bannersService = resp;
              this.banners = this.mode === 'slider' ? this.setSliderBanners(resp) : resp;
              if(this.mode === 'NECESITA'){
                this.setImages.emit(this.banners[0]);
              }


            }
          })
        );
      // }

    } else {
      this.subscriptions.push(
        this.carouselService.getBanners().subscribe((resp: Array<Carousel>) => {
          if (resp.length > 0) {
            this.banners = resp;

          }
        })
      );
    }
  }

  // Arranges banner's structure for slider
  private setSliderBanners(banners: Carousel[]): any[] {
    let resolution = window.innerWidth;
    let quantityImg: number;

    if ( resolution < 576 ) {
      quantityImg = 1;
    } else if ( resolution >= 576 && resolution < 768 ) {
      quantityImg = 2;
      quantityImg = quantityImg > this.quantity ? this.quantity : quantityImg;
    } else if ( resolution >= 768 && resolution < 992 ) {
      quantityImg = 3;
      quantityImg = quantityImg > this.quantity ? this.quantity : quantityImg;
    } else if ( resolution >= 992 ) {
      quantityImg = 8;
        quantityImg = quantityImg > this.quantity ? this.quantity : quantityImg;
       // quantityImg = quantityImg > this.quantity ? this.quantity : this.quantity ;
    }

    let sliderBanners = [];
    let internalBanner = {
      arrayOfBanners: []
    };
    let index = 1;
    if  (banners && banners.length){
      banners.forEach((banner: any) => {

        if (index > quantityImg) {
          const arrayOfBanners = { arrayOfBanners: [...internalBanner.arrayOfBanners] };
          sliderBanners.push(arrayOfBanners);
          internalBanner.arrayOfBanners = [];
          index = 1;
        }

        internalBanner.arrayOfBanners.push(banner);
        index++;
      });

      if (internalBanner.arrayOfBanners.length > 0) {
        sliderBanners.push(internalBanner);
      }

      return sliderBanners;

    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  goToBanner(banner:any){
    this.setImages.emit(banner);

  }

}
