import { Component, OnInit, ViewChild } from '@angular/core';
import { Aval, CurseService, FilterCurse, Modalidad, Vendedor} from '../../services/curse.service';
import { error } from 'highcharts';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

import { FilterGet } from '../../product/main/product.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Specialty } from '../../services/especialidad.service';
import { Categoria, Marca, TipoProducto } from '../../services/product.service';
import { FilterComponent } from '../../shared/filter/filter.component';
import { COMMON } from '../../shared/utils/common-texts.utils';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-courses-main',
  templateUrl: './courses-main.component.html',
  styleUrls: ['./courses-main.component.scss']
})
export class CoursesMainComponent implements OnInit {
  @ViewChild('chipsUpdate', { static: false }) chipsUpdate: FilterComponent;

  filterCurse: FilterCurse;
  cursos: Array<any> = [];
  avales: Array<Aval> = [];
  specialty: Array<Specialty>;
  modalidades: Array<Modalidad> = [];
  vendedores: Array<Vendedor> = [];
  idAval = 0;
  idModalidad = 0;
  idVendedor = 0;
  idSpecialty = 0;
  showResult: boolean;
  distancia: '';
  texto: '';
  chips = [];
  noHayResultadosFiltro = COMMON.filtro.sin_resultado;
  noExistenCursos = COMMON.curso.no_existen_cursos;
  buscandoResultadosFiltro = COMMON.filtro.buscando_resultado;
  physicalAddress: string;
  placeholderText: string;
  courseForm: FormGroup;
  showComponent: boolean;
  dir: string;

  constructor(private curseService: CurseService,
              private router: Router,private fb: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private toastService: ToastrService) {
    this.showComponent = false;
    this.createForm();
    this.activatedRoute.queryParams.subscribe((params) => {

      if (params.vendedor) {
        this.chips = [];
        this.chips.push({
          id: params.vendedor,
          tipo: 'Vendedor',
          keyType: 'vendedor',
          nombre: params.nombre,
          filter: true,
        });
        this.searchFilter();
      }else{
        this.getAllCourses();
      }
    });
  }

  createForm(): void {
    this.courseForm = this.fb.group({
      ciudad: [''],
      ubicacionDir: [''],
      longitude: ['' , Validators.required],
      latitude: ['' , Validators.required],
    });

  }
  ngOnInit(): void {
    this.placeholderText = 'Buscar cerca de: ';
    this.getAllFilter();
    this.physicalAddress = 'Direccion de dictado del curso';
    this.showComponent = true;
    this.dir = '';
  }

  getAllFilter(): void {
    this.showResult = false;
    this.curseService.getFilterCourse().subscribe((response: FilterCurse) => {
      this.filterCurse = response;
      this.showResult = true;
      if (response.avales.length && !this.chips.filter(chip => chip.keyType === 'avales').length){
        this.avales = [];
        this.avales = response.avales;
      }
      if (response.modalidades.length && !this.chips.filter(chip => chip.keyType === 'modalidad').length){
        this.modalidades = [];
        this.modalidades = response.modalidades;
      }
      if (response.especialidades.length ){
        this.specialty = response.especialidades;
      }
      if (response.vendedores.length && !this.chips.filter(chip => chip.keyType === 'vendedor').length){
        this.vendedores = [];
        this.vendedores = response.vendedores;
      }
      this.chipsUpdate.ngOnInit();
    }, (errorResponse: HttpErrorResponse) => {
      this.toastService.error(`${errorResponse.message}.`, 'Error');
    });
  }

  getAllCourses(params?): void {

    this.curseService.getAllCourses(params).subscribe((response: Array<any>) => {
      this.cursos = response;
    }, (errorResponse: HttpErrorResponse) => {
      this.toastService.error(`${errorResponse.message}.`, 'Error');
    });
  }

  filter(parmas): void{
    this.chips = parmas;
    this.searchFilter();
  }
  filterGoogleMaps(): void{
    this.chips = this.chips.filter(chip => chip.keyType !== 'location');

    this.chips.push({
      latitud: this.courseForm.value.latitude,
      longitud: this.courseForm.value.longitude,
      nombre: this.courseForm.value.ciudad,
      keyType: 'location',
      filter: true,
    });

    this.searchFilter();
  }

  goToCourseDetail(idCourse): void{
    this.router.navigate(['courses/', idCourse]);
  }

  deleteSelected(chipSelected): void{
    this.chips = this.chips.filter(chip => chip.id !== chipSelected.id && chip.keyType !== chipSelected.keyType );
    this.searchFilter();
    this.getAllFilter();
  }

  selected(ev, value): void{
    if (ev === 'aval' ){
      this.chips = this.chips.filter(chip => chip.keyType !== 'avales' );
      this.chips.push({
        id: value.id,
        tipo: 'Avales',
        keyType: 'avales',
        nombre: value.nombre,
      });
    }

    if (ev === 'modalidad' ){
      this.chips = this.chips.filter(chip => chip.keyType !== 'modalidad' );
      this.chips.push({
        id: value.id,
        tipo: 'Modalidad',
        keyType: 'modalidad',
        nombre: value.nombre,
      });
    }

    if (ev === 'vendedor' ){
      this.chips = this.chips.filter(chip => chip.keyType !== 'vendedor' );
      this.chips.push({
        id: value.id,
        tipo: 'Vendedor',
        keyType: 'vendedor',
        nombre: value.razonSocial,
      });
    }

    if (ev === 'especialidad' ){
      this.chips = this.chips.filter(chip => chip.keyType !== 'especialidad' );
      this.chips.push({
        id: value.id,
        tipo: 'Especialidad',
        keyType: 'especialidad',
        nombre: value.nombre,
      });
    }

    this.searchFilter();

  }

  onChange(deviceValue) {
    this.distancia = deviceValue.value;
    if (this.chips && this.chips.length){
      this.chips = this.chips.filter(chip => chip.keyType !== 'distancia' );
    }
    if (deviceValue.value !== ''){
      this.chips.push({
        id: 0,
        tipo: 'Distancia',
        keyType: 'distancia',
        nombre: deviceValue.value,
      });
    }
    this.searchFilter();
  }

  searchFilter(): void{

    const params: FilterGet = {};
    this.chips.forEach((chip) => {

      if (chip.keyType === 'modalidad') {
        params.modalidad = String(chip.id);
        this.modalidades = [];
      }

      if (chip.keyType === 'vendedor') {
        params.vendedor = String(chip.id);
        this.vendedores = [];
      }

      if (chip.keyType === 'avales') {
        params.aval = String(chip.id);
        this.avales = [];
      }

      if (chip.keyType === 'especialidad') {
        params.especialidad = chip.id;
      }

      if (chip.keyType === 'texto'){
        params.texto = chip.nombre;
      }

      if (chip.keyType === 'distancia'){
        params.distancia = chip.nombre;
      }

      if (chip.keyType === 'location'){
        params.latitud = chip.latitud;
        params.longitud = chip.longitud;
      }


    });
    if (params.texto || params.vendedor || params.longitud || params.latitud ||
      params.modalidad || params.aval ||  params.especialidad || params.distancia){

      this.getAllCourses(params);
    }else{
      this.getAllCourses();
    }
  }
}
