import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { apiBaseUrl } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Specialty } from './especialidad.service';
import { ApiService } from '../core/api.service';
import {
  ProductosUpdate,
  ProductsRegister,
  VendeProductos
} from '../form/form-products-register/interface-products-register';
import {Curso} from "../form/form-my-courses/interface-my-courses";

export interface TipoProducto{
  id: number;
  nombre: string;
  selected?: boolean;
}

export interface Marca{
  id: number;
  nombre: string;
  selected?: boolean;
  requerir?: boolean;
  imagen?: string;
}

export interface Variante{
  value: string;
  selected?: boolean;
}
export interface Product{
  id: number;
  nombre: string;
  tipoProducto: TipoProducto;
  marca: Marca;
  link: string;
  folleto?: string;
  variantes: string;
  descripcion: string;
  puntuacion: number;
  imagen: string;
  puntuaciones: number;
  imagenes: Array<string>;
  favorito: boolean;
  precioDesde?: number;
  vendedores: VendedorBasico[];
  cursos?: Curso[];
  slug?: string;
  vendeproducto?: VendeProductos;


  // frontend
  vars: string[];
}

export interface VendedorBasico {
  nombre: string;
  imagen: string;
}

export interface Imagen{
  selected?: boolean;
  urlImg: string;
}

export interface Categoria{
  id: number;
  orden?: number;
  nombre: string;
  imagen?: null;
  destacado?: boolean;
  selected?: boolean;
}
export interface Subcategoria{
  id: number;
  orden: number;
  nombre: string;
  imagen: string;
  destacado: boolean;
  selected?: boolean;
}
export interface Response{
  principales: Array<Categoria>;
  filtros: Filter;
  productos: Array<Product>;
  posts?: Array<any>;
  favoritos: number[];
}

export interface Filter {
  especialidades: Array<Specialty>;
  categorias: Array<Categoria>;
  subcategorias: Array<any>;
  tipoProductos: Array<TipoProducto>;
  marcas: Array<Marca>;
}

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  productPath = '/productos';
  odontoPath = '/odontologo';
  productFilterPath = '/productos/filter';

  constructor(private apiService: ApiService) { }

  getProductById(idProduct: string): Observable<Product> {
    return this.apiService.get(apiBaseUrl, `${this.productPath}/id/${idProduct}`)
      .pipe(
        map((response) => {
          return response.payload;
        })
      );
  }

  getProduct(params?): Observable<Response> {
    return this.apiService.get(apiBaseUrl, this.productPath, params)
      .pipe(
        map((response) => {
          return response.payload;
        })
      );
  }

  getProductFilter(params?): Observable<Response> {
    return this.apiService.get(apiBaseUrl, this.productFilterPath, params)
      .pipe(
        map((response) => {
          return response.payload;
        })
      );
  }

  getInfoProductos(): Observable<Response> {
    return this.apiService.get(apiBaseUrl, this.productPath + '/info')
      .pipe(
        map((response) => {
          return response.payload;
        })
      );
  }

  getProductosVendedor(params?: any): Observable<Response> {
    return this.apiService.get(apiBaseUrl, this.productPath + '/vendeproducto-v2', params)
      .pipe(
        map((response) => {
          return response.payload;
        })
      );
  }

  saveMarcasVendedor(data): Observable<Response> {
    return this.apiService.post(apiBaseUrl, this.productPath + '/vendedor/marcas', data);
  }

  saveProductosVendedor(data): Observable<Response> {
    return this.apiService.post(apiBaseUrl, this.productPath + '/vendedor/productos', data);
  }

  getSellProducts(params?): Observable<ProductsRegister> {
    return this.apiService.get(apiBaseUrl, `${this.productPath}/vendeproducto`, params)
      .pipe(
        map((response) => {
          return response.payload;
        })
      );
  }

  getSeller(): Observable<any> {
    return this.apiService.get(apiBaseUrl, `${this.odontoPath}/vendedores/footer`)
      .pipe(
        map((response) => {
          return response.payload;
        })
        );
  }

  postSellProducts(products: ProductosUpdate): Observable<any> {
    return this.apiService.post(apiBaseUrl, `${this.productPath}/vendeproducto`, products)
      .pipe(
        map((response) => {
          return response.payload;
        })
      );
  }

  recomendarProducto(data: any): Observable<Response> {
    return this.apiService.post(apiBaseUrl, this.productPath + '/recomendar', data);
  }

  getCotizaciones(): Observable<any> {
    return this.apiService.get(apiBaseUrl, this.odontoPath + '/cotizaciones')
  }

}

