import {Component} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {environment} from '../environments/environment';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
// tslint:disable-next-line:ban-types
declare let gtag: Function;
// tslint:disable-next-line:ban-types
declare let fbq: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private router: Router,
    private gtmService: GoogleTagManagerService,
  ) {
    if (environment.production) {
      router.events.subscribe((y: NavigationEnd) => {
        if (y instanceof NavigationEnd) {
          gtag('config', 'G-W1PLV7MTDC', {page_path: y.url});
          fbq('track', 'PageView');

          const gtmTag = {
            event: 'page',
            pageName: y.url
          };
          this.gtmService.pushTag(gtmTag);
        }
      });
    }
  }

}
