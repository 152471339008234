
<div class="card radius-10">
  <div class="card-body">
    <div class="d-flex align-items-center">
      <div>
        <h5 class="mb-0">Detalle del carrito</h5>
      </div>
      <div class="font-22 ms-auto"><i class='bx bx-dots-horizontal-rounded'></i>
      </div>
    </div>
    <hr/>
    <div class="table-responsive">
      <table class="table align-middle mb-0">
        <thead class="table-light">
        <tr >
          <th></th>
          <th>Productos</th>
          <th>Cantidad</th>
          <th>Mejor precio</th>
          <th *ngFor="let col of headerVededor">{{col.nombre}}</th>
          <th>Accion</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let p of productosPorPrecio">
          <td style="max-width: 50px;">
            <img [src]="p.imagen? p.imagen : 'assets/images/icons/chair.png' " [alt]="p.nombre" style="max-width: 100%">
          </td>
          <td>
            <div class="d-flex align-items-center">
              <div class="ms-2 text-start">
                <h6 class="mb-0 font-14">{{p.nombre}}</h6>
                <p class="mb-0 font-13 text-secondary">{{ p.marca ? p.marca.nombre : ''}} {{ p.variante ? '| '+ p.variante : ''}}</p>
              </div>
            </div>
          </td>
          <td class="cantidad">
            <div class="input-group input-spinner">
              <button class="btn btn-white" type="button" (click)="subtract(p)"> −</button>
              <input type="text" class="form-control text-center" [(ngModel)]="p.cantidad" disabled >
              <button class="btn btn-white" type="button" (click)="plus(p)"> +</button>
            </div>
          </td>
          <td  *ngIf="p.mejorPrecio !== DEFAULT_NULL_PRICE">
            <div class="d-flex align-items-center">
              <div class="recent-seller-img"  (click)="sendBuy(content, contentModalLogin, contentModalEditData, p)">
                <img [src]="p.vendedormejorPrecio.logo? p.vendedormejorPrecio.logo : p.vendedormejorPrecio.imagen " alt="">
              </div>
              <div class="ms-2 align-middle me-1" >
                {{'$' + (p.mejorPrecio)}}
              </div>
            </div>
          </td>
          <td *ngIf="p.mejorPrecio === DEFAULT_NULL_PRICE">
            <div class="ms-2 align-middle me-1" >
              No disponible
            </div>
          </td>
          <td *ngFor="let pv of p.vendedores" >
            <div class="align-items-center text-danger" *ngIf="pv.precio<1 || pv.precio  == DEFAULT_NULL_PRICE">
              <i class='bx bx-radio-circle-marked bx-burst bx-rotate-90 align-middle font-18 me-1'></i>
              <span>No disponible</span>
            </div>
            <div *ngIf="pv.precio  !== DEFAULT_NULL_PRICE">
              <i class="fadeIn animated bx bx-message-square-detail me-1" *ngIf="pv.comentario"
                 ngbPopover='{{pv.comentario}}'
                 [openDelay]="300"
                 [closeDelay]="500"
                 triggers="mouseenter:mouseleave">
              </i>
              <ng-container *ngIf="(pv.oferta ? pv.oferta : pv.precio) as price">
                <span [title]="'Precio por unidad $ ' + price"> ${{(price * p.cantidad)}}</span>
              </ng-container>
            </div>
          </td>
          <td>
            <div class="d-flex order-actions">
              <button type="button" class="btn ms-4 add" (click)="delete(p)"><i class="bx bx-trash me-0"></i></button>
            </div>
          </td>
        </tr>
        <br>
        </tbody>
        <tbody>
        <tr>
          <td></td>
          <td></td>
          <td *ngFor="let col of headerVededor"><td>
        </td><td>
        </tr>
        <tr>
          <td>
            Envío:
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td *ngFor="let col of headerVededor">
            {{col.tipoEnvio >0 ? '$' + col.tipoEnvio : col.tipoEnvio   }}

          </td>
          <td></td>
        </tr>
        <tr>
          <td>
            Total:
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td *ngFor="let col of headerVededor">$ {{col.precioTotal}}</td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<ng-template #content let-modal>
  <div class="modal-content">
    <div class="modal-header ">
      <h5 class="modal-title">Este es el mejor precio en Todo-Odonto.</h5>
    </div>
    <div class="modal-body">
      <p>Vas a realizar una orden de compra directamente con el vendedor por este producto. ¿Estás seguro de confirmar la compra?</p>
      <div class="p-2 center-card" *ngIf="product">
        <app-card-single-product-purchase [product] = product>
        </app-card-single-product-purchase>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="closeModal()">Cancelar</button>
      <button type="button" class="btn btn-dark" (click)="sendPurchase()">Confirmar compra</button>
    </div>
  </div>
  <!--  end mobile-->
</ng-template>


<ng-template #contentModalLogin let-modal>

  <div class="modal-content">
    <div class="modal-header ">
      <h5 class="modal-title">Atencion!!</h5>
    </div>
    <div class="modal-body">
      <p>
        Para poder realizar una compra debes estar registrado en el sistema como cliente.
      </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="goToRegister()">Registrarme</button>
      <button type="button" class="btn btn-dark" (click)="goToLogin()">Iniciar sesion</button>
    </div>
  </div>
  <!--  end mobile-->


</ng-template>
<ng-template #contentModalEditData let-modal>

  <div class="modal-content">
    <div class="modal-header ">
      <h5 class="modal-title">Atencion!!</h5>
    </div>
    <div class="modal-body">
      <p>
        Para poder realizar una compra debes estar registrado en el sistema como cliente.
      </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-dark" (click)="goToProfile()">Actualizar mis datos</button>
    </div>
  </div>
  <!--  end mobile-->


</ng-template>
